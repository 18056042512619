.breadcrumbRoot {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.breadcrumbLabel {
    font-size: 13px;
    color: #a9a9a9;
}