.rootArea {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.managingArea {
    display: flex;
    flex-direction: column;
}

.separatedSection {
    border: 1px dashed lightgray;
    border-left: none;
    border-right: none;
    margin-top: 10px;
    margin-bottom: 10px;
}