.header {
    display: flex;
    width: 100%;
    justify-content: right;
    align-items: center;
}

.nameCell {
    width: 25%;
    font-size: 14px;
}

.emailCell {
    width: 20%;
}

.roleCell {
    width: 10%;
}

.expireDateCell {
    width: 15%;
}

.companyCell {
    width: 25%;
}

.blockedCell {
    width: 5%;
}

.textArea {
    text-overflow: ellipsis;
    max-width: 450px;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px !important;
}

@media screen and (max-width: 1270px) {
    .textArea {
        max-width: 200px;
    }
}

@media screen and (max-width: 1070px) {
    .textArea {
        max-width: 100px;
    }
}