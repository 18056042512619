.customButton {
  width: 410px;
  height: 50px;
  font-family: "Varela Round";
  text-indent: 10px;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 400;
  background-color: var(--success-primary);
  cursor: pointer;
}

.customButton:active {
  background-color: var(--success-secondary);
}
