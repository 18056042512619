.header {
    display: flex;
    width: 100%;
    justify-content: right;
    align-items: center;
}

.titleCell {
    width: 40%;
}

.status {
    width: 20%;
}

.createdAt {
    width: 20%;
}

.processingPercentage {
    width: 10%;
    text-align: center !important;
}

.textArea {
    text-overflow: ellipsis;
    max-width: 450px;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px !important;
}

@media screen and (max-width: 1270px) {
    .textArea {
        max-width: 200px;
    }
}

@media screen and (max-width: 1070px) {
    .textArea {
        max-width: 100px;
    }
}