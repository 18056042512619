.container {
  height: 100%;
  background-color: var(--container-primary);
  padding: 20px;
}

@media (max-width: 700px) {
  .container {
    width: auto;
  }
}
