.headerArea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
}

.headerLabel {
    font-weight: bold;
    font-size: 18px;
    margin-top: 7px;
}