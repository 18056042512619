.customTextForm {
  width: 410px;
  font-size: 18px;
  font-family: "Varela Round";
  height: 50px;
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid black;
  padding: 1px 6px;
}
.customTextForm:focus-visible {
  outline: none;
}
