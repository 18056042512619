.contextMenu {
    z-index: 9999999;
}

.contextMenuElement {
    background-color: white;
    padding: 7px 5px;
    border: 1px solid lightgray;
    border-radius: 5px;
}

.contextMenuElement:hover {
    font-weight: bolder;
    cursor: pointer;
}