.root {
    width: 100%;
}

.paper {
    width: 100%;
    margin-bottom: 2px;
}

.table {
    min-width: 750px;
}