.header {
    display: flex;
    width: 100%;
    align-items: center;
}

.containerBg {
    display: flex;
    flex-direction: column;
    background-color: var(--inputs-primary);
    margin: 10px 20px;
    border-radius: 4px;
}