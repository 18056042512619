.header {
    display: flex;
    width: 100%;
    justify-content: right;
    align-items: center;
}

.titleCell {
    width: 35%;
    font-size: 14px;
}

.ucapCell {
    width: 15%;
}

.profcapCell {
    width: 15%;
}

.usersCell {
    width: 15%;
}

.profsCell {
    width: 15%;
}

.textArea {
    text-overflow: ellipsis;
    max-width: 450px;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px !important;
}

@media screen and (max-width: 1270px) {
    .textArea {
        max-width: 200px;
    }
}

@media screen and (max-width: 1070px) {
    .textArea {
        max-width: 100px;
    }
}