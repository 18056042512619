.visuallyHidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 20px;
    width: 1px;
}

.headerCell {
    font-family: "Varela Round", sans-serif !important;
}