.containerBg {
    display: flex;
    flex-direction: column;
    background-color: var(--inputs-primary);
    margin: 10px 20px;
    border-radius: 4px;
    width: 100%;
}

.dataContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}