.rootArea {
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    max-height: 63vh;
    min-height: 61vh;
    display: flex;
    padding: 15px;
    width: 25%;
    overflow-y: auto;
}

.rootArea::-webkit-scrollbar-track {
    border: 1px solid #ffffff;
    padding: 2px 0;
    background-color: #ffffff;
}

.rootArea::-webkit-scrollbar {
    width: 2px;
}

.rootArea::-webkit-scrollbar:hover {
    width: 5px;
}

.rootArea::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /*box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
    background-color: #737272;
    border: 1px solid lightgray;
}