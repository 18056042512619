.rootArea {
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    max-height: 63vh;
    min-height: 96%;
    display: flex;
    padding: 15px;
    width: 68%;
    overflow-y: auto;
}