.outerBox {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainForm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50px;
  background-color: var(--nav-primary);
  width: 530px;
  height: 250px;
}

.container {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  width: 560px;
  height: 290px;
}

.logo_img {
  width: 25px;
  height: 25px;
  align-self: flex-start;
  left: 20px;
  top: 35px;
  position: absolute;
}
