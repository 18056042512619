.requestedStatus {
    color: #314e65;
}

.doneStatus {
    color: var(--success-primary);
}

.rejectedStatus {
    color: var(--color-red);
}