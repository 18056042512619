.checkboxArea {
    display: flex;
    flex-direction: row;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 10px 5px;
    margin-bottom: 10px;
}

.blockedCheckboxArea {
    display: flex;
    flex-direction: row;
    border: 1px solid var(--color-red);
    border-radius: 5px;
    padding: 10px 5px;
    margin-bottom: 10px;
}