.appBar {
    flex-grow: 1;
    background-color: white !important;
    height: 80px;
}

.tab {
    color: #000000;
    font-size: 18px;
    font-family: Roboto sans-serif;
    text-transform: none;
    letter-spacing: 2px;
}

.tabContainer {
    width: 85%;
}

.indicator {
    background-color: var(--color-red);
}

.logoTitle {
    font-size: 22px;
    color: black;
    max-width: 180px;
    text-align: center;
    font-weight: 400;
    letter-spacing: 0.1em;
}

.logoImg {
    height: 65px;
    margin: 10px 15px;
}