.containerBg {
    display: flex;
    flex-direction: column;
    background-color: var(--inputs-primary);
    margin: 10px 20px;
    border-radius: 4px;
}

.outerContainer {
    display: flex;
}