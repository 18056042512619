.loadBar {
  top: 0;
  left: 0;

  position: fixed;
  display: flex;

  height: 20px;
  width: 100%;

  overflow: hidden;
  background: var(--color-red);
  transition: width 0.6s ease;

  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;

  z-index: 99999;
}

.loadBar.stripped {
  background-image: linear-gradient(
    315deg,
    var(--color-yellow) 25%,
    #e2000000 25%,
    #ff000000 50%,
    var(--color-yellow) 50%,
    var(--color-yellow) 75%,
    var(--color-red) 75%,
    var(--color-red)
  );
  background-size: 2rem 2rem;

  animation: slider-animation 0.7s infinite linear;
  animation-delay: 0s;
}

@keyframes slider-animation {
  0% {
    background-position-x: 2rem;
  }
}

.loadingBackground {
  left: 0;
  top: 0;

  position: fixed;
  display: flex;

  height: 100%;
  width: 100%;

  z-index: 99998;

  background: #5b5858;
  opacity: 0.5;
}
