:root {
  --color-red: #d35f5f;
  --color-red-hover: #984444;
  --color-red-transparent-hover: #f6ebeb;
  --color-yellow: #e0dea3;
  --color-yellow-hover: #8c895f;
  --background-color: #dce3ed;
  --resources-primary: #314e65;
  --container-primary: #f9fbfe;
  --nav-primary: #ffffff;
  --modal-primary: #e5e5e5;
  --inputs-primary: #f8fcff;
  --success-primary: #79cd7d;
  --success-secondary: #69aa6c;
}
body {
  padding: 0;
  margin: 0;
  font-family: "Roboto" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
