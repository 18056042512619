.separationLine {
    border: 1px solid #E5E5E5;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
}

.mainElements {
    display: flex;
    flex-direction: column;
}

.formArea {
    display: flex;
    flex-direction: column;
}

.selectAllArea {
    display: flex;
    flex-direction: row;
    vertical-align: center;
}

.selectAllLabel {
    padding: 9px;
}

.selectAllLabel:hover {
    cursor: pointer;
}