.root {
    display: flex;
}

paper {
    margin-right: 2px;
}

.link {
    font-family: Roboto;
    color: #000000;
    margin: 13px 10px;
    font-size: 18px;
    margin-left: 70px;
    opacity: 0.7;
    letter-spacing: 1px;
    display: flex;
}

.link:hover {
    cursor: pointer;
}

.expandIcon {
    color: #e4e4e4;
}

.menuElement {
    font-size: 14px;
}

.menuIcon {
        color: var(--color-red);
}

.localizationSwitch {
    margin-top: 10px;
    display: flex;
    font-size: 14px;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid gray;
}