.rootArea {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.managingArea {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}