.questionHeaderLabel {
    color: var(--color-red);
}

.separationLine {
    border: 1px solid #E5E5E5;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 15px;
}

.mainElements {
    display: flex;
    flex-direction: column;
}

.formArea {
    display: flex;
    flex-direction: column;
}