.header {
    display: flex;
    width: 100%;
    justify-content: right;
    align-items: center;
}

.textCell {
    width: 35%;
}

.ordinanceCell {
    width: 25%;
}

.chapterCell {
    width: 20%;
}

.pointCell {
    width: 20%;
}

.imageCell {
    width: 5%;
}

.textArea {
    text-overflow: ellipsis;
    max-width: 450px;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px !important;
}

@media screen and (max-width: 1270px) {
    .textArea {
        max-width: 200px;
    }
}

@media screen and (max-width: 1070px) {
    .textArea {
        max-width: 100px;
    }
}