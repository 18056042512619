.chipsSelectionArea {
    display: flex;
    flex-direction: column;

    width: 100%;
    margin-bottom: 100px;
    margin-top: 20px;
}

.mainElements {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
}